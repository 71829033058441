.bordered-list {
  .list-group-item {
    border-left: 3px solid transparent;

    &:hover,
    &.active {
      border-left: 3px solid #696969;
    }
  }
}

.rtl {
  .bordered-list {
    .list-group-item {
      border-left: 0 !important;
      border-right: 3px solid transparent !important;

      &:hover,
      &.active {
        border-left: 0 !important;
        border-right: 3px solid #696969 !important;
      }
    }
  }
}

// Google Places autosuggest fix for Modal popups
div.pac-container {
  z-index: 9999999 !important;
}

// Customer Site Information button styles
.btnVariableWidth {
  width: 100%;
  margin-bottom: 10px;
}

.paddingLeftVariable {
  padding-left: 10px;
}

/* New media query for desktop screens */
@media (min-width: 768px) {
  .btnVariableWidth {
    width: 150px;
    margin-right: 10px;
    /* Fixed width for desktop */
  }

  .paddingLeftVariable {
    padding-left: 0;
  }
}

.card-body {
  background-color: white;
}

.card-title {
  background-color: white;
}

.card-rounded-top {
  border-top-right-radius: 16px;
  border-top-left-radius: 16px;
}

.card-rounded-bottom {
  border-bottom-left-radius: 16px;
  border-bottom-right-radius: 16px;
}

.card-rounded {
  border-top-right-radius: 16px;
  border-top-left-radius: 16px;
  border-bottom-left-radius: 16px;
  border-bottom-right-radius: 16px;
}

.uppy-Dashboard-innerWrap {
  background-color: white;
}

.uppy-Webcam-videoContainer .uppy-Webcam-video {
  width: 288px;
  /* new width */
  height: 216px;
  /* new height */
}

.uppy-Webcam-container {
  position: relative;
  /* This will be the reference for the absolute positioning of the button */
}

.uppy-Webcam-buttonContainer {
  position: absolute;
  /* Position the button container absolutely to place it on top of the video */
  top: 0;
  /* Align it to the top of the parent container */
  left: 0;
  /* Align it to the left of the parent container */
  z-index: 10;
  /* Ensure it sits on top of other elements */
}

.container-fluid,
.container-lg,
.container-md,
.container-sm,
.container-xl,
.container-xxl {
  padding-right: 0px;
  padding-left: 0px;
}

.hidePrint {
  display: none;
}

.modal-xl {
  --bs-modal-width: 1600px;
}
